import React from 'react'
import "../scss/components/curve.scss"

export default function Curve({fillClass = 'white', direction}) {
    const directionClass = direction === 'down' ? 'rotate-180' : ''
    return (
        <svg
            width='375'
            height='18'
            viewBox='0 0 375 18'
            preserveAspectRatio='xMinYMin'
            className={`curve transparent w-100 h-auto mx-auto ${directionClass}`}
        >
            <path fill={fillClass} d="m187.5,0c62.77,0,125.54,5.89,187.5,17.66V0h-187.5Z"/>
            <path fill={fillClass} d="m0,0v17.66C61.97,5.89,124.73,0,187.5,0H0Z"/>
        </svg>
    )
}
