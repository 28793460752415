/**
 * Counter Year After Year Landing Page
 */

import React, { Component } from "react"
import { Helmet } from 'react-helmet'
import { graphql } from "gatsby"
import get from "lodash/get"
import Img from "gatsby-image"
import { StaticImage } from "gatsby-plugin-image"
import WufooForm from "react-wufoo-embed"

import LayoutLander from "../components/layoutLander"
import Seo from "../components/seo"
import Curve from "../components/curve"
import { Carousel } from "react-responsive-carousel"
import "../scss/pages/yearafteryear.scss"

class YearAfterYear extends Component {

  componentDidMount() {
    // Replacing &reg; elements
    let regElements = document.querySelectorAll(
      "main h2, main h3, main p, main .btn, .card__desc, .hero__title"
    )
    regElements.forEach(function (el) {
        el.innerHTML = el.innerHTML.replace(/®/g, '<sup>&reg;</sup>');
    })
  }

  render() {
    // set all those variables based on contentful query
    const plantRoot = get(this, "props.data.plantRoot")
    const rowUnit = get(this, "props.data.rowUnit")
    const rowUnitMobile = get(this, "props.data.rowUnitMobile")

    return (
      <LayoutLander bodyClass="counter-yearafteryear">
        <Helmet>
          <meta name="theme-color" content="#d3a029" />
        </Helmet>
        <Seo
          title="Year After Year | COUNTER 20G"
          description="Save money while controlling nematodes on every pass with COUNTER® 20G SmartCartridge® from SIMPAS-applied Solutions."
          robots="noindex, nofollow"
        />

        <section className="yearafteryear-hero bg--black">
        <Img
            fluid={plantRoot.fluid}
            alt="COUNTER® 20G SmartCartridge"
            className="gs__img"
            loading="eager"
        />
          <div className="container flow--sm">
              <h1>Save money while <br/><span className="text--orange">controlling nematodes,</span></h1>
              <h2>Year After Year, After Year, After Year, After Year, After Year, After&nbsp;Year</h2>
              <p>As you know, no field is immune to the potential for nematode damage. In some cases, nematode problems have caused farmers 
                to lose up to 30 bushels per acre! Choose COUNTER® 20G SmartCartridge® to protect your yield. It’s proven to be an effective 
                strategy, and the industry standard, to control nematodes and the damage they inflict on your&nbsp;corn.</p>
              <p>And when you apply COUNTER 20G with the SIMPAS® SmartCartridge closed application system, you’re prescriptively treating 
                the problem areas with the exact amount of product precisely where it’s needed — which saves time, increases your yield 
                and saves money, year after year after&nbsp;year.</p>
          </div>
          <Curve direction="down" />
        </section>

        <section className="yearafteryear-content">
          <div className="container flow--thick">
            <div className="row">
              <div className="col text-left">
                <h2>Only What’s Prescribed.<br/>Precisely Where It’s Needed.</h2>
                <div className="text-wrap-group">
                    <img className="wrapped-image"
                         width="130" height="266"
                         src={rowUnitMobile.file.url}
                         alt={rowUnitMobile.description}
                    />
                    <div className="wrapping-text">
                        <p>The SIMPAS application system is a versatile and effective technology that allows farmers to apply up to three inputs 
                            in-furrow to areas of the field that need a prescriptive approach. All three inputs can be applied at the same time, 
                            in one pass, to save you time and effort.</p>
                        <p>With a wide portfolio of products available to use — insecticides, fungicides, nematicides, micronutrients, and soil health 
                            products — farmers can now proactively address problem areas in row crops by applying exactly 
                            what the soil or plant needs, precisely where it is needed.</p>
                        <p>Where once they may have left a problem area untreated and taken the economic consequence, they now can apply these products 
                            prescriptively — using only the exact amount of product needed — to maximize their yield.</p>
                        <p>Better still, these products are contained in easy-to-handle cartridges for quick and clean handling. And the SIMPAS system 
                            itself is designed to fit most planters in the market. So now you can quickly and efficiently treat your fields to make 
                            the largest ROI&nbsp;possible.</p>
                    </div>
                </div>
              </div>
              <div className="col">
                <Img
                    fluid={rowUnit.fluid}
                    alt={rowUnit.description}
                    className="gs__img"
                />
              </div>
            </div>
          </div>
        </section>

        {/* <section className="yearafteryear-testimonials">
          <div className="container text-center flow--thick">
            <div className="testimonial-header row">
              <div className="container--width-sm">
                <h2 className="text-white">Proof From the&nbsp;Field.</h2>
                <p>Don't just listen to us. See what people like you have to say about SIMPAS-applied Solutions.</p>
              </div>
            </div>
            <div className="testimonials row">
              <StaticImage
                src="../images/quote-marks.png"
                alt=""
                className="quote__img"
              />
              <Carousel
                showThumbs={false}
                showStatus={false}
                showArrows={false}
                autoPlay={true}
                interval="8500"
                infiniteLoop={true}
                className="hero--carousel testimonial--carousel"
              >
              <div className="row">
                <div className="col col-sm-3 col-md-4 text-center">
                  <StaticImage
                    src="../images/quote-jason-orr.png"
                    alt=""
                    className="gs__img"
                  />
                </div>
                <div className="col text-left">
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerci.</p>
                  <p className="name">NAME, State</p>
                </div>
              </div>
              <div className="row">
                <div className="col col-sm-3 col-md-4 text-center">
                  <StaticImage
                    src="../images/quote-jason-orr.png"
                    alt=""
                    className="gs__img"
                  />
                </div>
                <div className="col text-left">
                  <p>22222 Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerci.</p>
                  <p className="name">NAME 2, State 2</p>
                </div>
              </div>
              </Carousel>
            </div>
          </div>
        </section> */}

        <section className="yearafteryear-contact bg--white contact-content">
          <div className="container flow">
            <div className="row">
              <div className="col col-md-3 text-left">
                <h2>Learn <br/>More</h2>
                <p>To learn more, fill out this short form, and we'll give you a call within two business days. If we’re unable to connect after the two days, we’ll send you an email with more in-depth information about SIMPAS-applied Solutions®.</p>
              </div>
              <div className="col col-md-8">
                <WufooForm
                  userName="archermalmo"
                  formHash="q1gg2si909q1419"
                  header="hide"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="yearafteryear-disclaimer bg--white">
          <div className="container flow">
            <div className="row">
              <p className="text-center">COUNTER 20G is a restricted use pesticide. Important: Always read and follow label instructions. See label for listed weeds and pests. 
                Some products may not be registered for sale in or use in all states or countries. Please check with your local extension service to ensure registration status. 
                COUNTER 20G is EPA registered.</p>
            </div>
          </div>
        </section>

      </LayoutLander>
    )
  }
}
export default YearAfterYear

export const YearAfterYearPageQuery = graphql`
  query YearAfterYearPageQuery {
    plantRoot: contentfulAsset(title: {eq: "Plant-Root"}) {
        id
        description
        title
        fluid(maxWidth: 375, resizingBehavior: PAD) {
          ...GatsbyContentfulFluid
        }
    }
    rowUnit: contentfulAsset(title: {eq: "red-row-unit-shadowed"}) {
      id
      description
      title
      fluid(maxWidth: 375, resizingBehavior: PAD) {
        ...GatsbyContentfulFluid
      }
    }
    rowUnitMobile: contentfulAsset(title: {eq: "red-row-unit-shadowed-mobile"}) {
      id
      description
      title
      file {
        url
      }
    }
  }
`
